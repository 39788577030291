import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputComponent from "../components/atoms/input";
import UserSVG from "../images/symbols/user.svg";
import EmailSVG from "../images/symbols/envelope.svg";
import CompanySVG from "../images/symbols/company.svg";
import Button, { ButtonTypes } from "../components/atoms/button";
import ContactsInfo from "../components/molecules/contactsInfo";
import SocialMedia from "../components/molecules/socialMedia";
import EmailSentComponent from "../components/molecules/emailSent";
import {
   FormTypes,
   submitHubSpotForm,
   validateEmail,
} from "../helpers/hubspotHelper";
import { sendContactEmail } from "../helpers/emailClient";

const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   background: var(--gradient-bg);
   color: var(--text-inverted);
   flex-grow: 1;

   .innerContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: var(--screen-max-width);
      margin: var(--screen-margin);
      padding: calc(100px + 88px) 0;
   }

   @media only screen and (max-width: 600px) {
      .innerContainer {
         padding: 100px 0;
      }
   }
`;

const LeftContainer = styled.div`
   max-width: 640px;
   display: flex;
   flex-direction: column;
   gap: 40px;
   flex-grow: 1;

   .textContainer {
   }

   .formContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
   }

   @media only screen and (max-width: 600px) {
      button,
      a {
         width: 100%;
         box-sizing: border-box;
      }
   }
`;

const RightContainer = styled.div`
   max-width: 320px;
   display: flex;
   flex-direction: column;
   gap: 52px;

   .hireWiseSection {
      display: flex;
      flex-direction: column;
      gap: 14px;
   }

   .hireWiseSection p:first-of-type {
      font-weight: 700;
   }
`;

const ContactUsTemplate = ({ isMobile }) => {
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [company, setCompany] = useState("");
   const [message, setMessage] = useState("");
   const [isInfoValid, setIsInfoValid] = useState(false);
   const [emailSent, setEmailSent] = useState(false);
   const delayFactor = (index) => 100 * index;

   const validateInfo = () => {
      const isNameValid = name.trim().length > 0;
      const isEmailValid = validateEmail(email);
      const isMessageValid = message.trim().length > 0;
      const isCompanyValid = company.trim().length > 0;

      setIsInfoValid(
         isNameValid && isEmailValid && isMessageValid && isCompanyValid
      );
   };

   useEffect(() => {
      validateInfo();
   }, [name, email, message, company]);

   const handleSubmit = async () => {
      const formData = {
         email,
         firstname: name,
         company,
         message,
      };
      setEmailSent(true);
      sendContactEmail(email, name);
      await submitHubSpotForm(formData, FormTypes.CONTACT);
   };

   return (
      <Container>
         <div className="innerContainer">
            <LeftContainer>
               <div className="textContainer">
                  <p
                     className="tagline"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(0)}
                  >
                     NEARSHORE.PT
                  </p>
                  <h1 data-aos="fade-up" data-aos-delay={delayFactor(1)}>
                     Contact us
                  </h1>
                  <p
                     className="subheading"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(2)}
                  >
                     Please fill in the form to get in touch.
                     <br />
                     Alternatively, you can contact us through{" "}
                     <span style={{ textDecoration: "underline" }}>
                        connect@nearshore.pt
                     </span>
                     .
                  </p>
               </div>
               {!emailSent ? (
                  <div
                     className="formContainer"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(3)}
                  >
                     <InputComponent
                        type="text"
                        value={name}
                        placeholder="Name"
                        handleChangeValue={(e) => setName(e.target.value)}
                        Icon={UserSVG}
                     />
                     <InputComponent
                        type="email"
                        value={email}
                        placeholder="Email"
                        handleChangeValue={(e) => setEmail(e.target.value)}
                        Icon={EmailSVG}
                     />
                     <InputComponent
                        type="text"
                        value={company}
                        placeholder="Company"
                        handleChangeValue={(e) => setCompany(e.target.value)}
                        Icon={CompanySVG}
                     />
                     <InputComponent
                        isTextArea
                        value={message}
                        placeholder="Message"
                        handleChangeValue={(e) => setMessage(e.target.value)}
                     />
                     <Button
                        content="Submit"
                        type={ButtonTypes.PRIMARY}
                        disabled={!isInfoValid}
                        onClick={handleSubmit}
                     />
                  </div>
               ) : (
                  <EmailSentComponent />
               )}
            </LeftContainer>
            {!isMobile && (
               <RightContainer>
                  <div
                     className="hireWiseSection"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(0)}
                  >
                     <p>HireWise</p>
                     <p>
                        Quickly and accurately calculate the cost-effectiveness
                        of our staffing solutions.
                     </p>
                     <Button
                        content="Calculate for free"
                        type={ButtonTypes.SECONDARY}
                        url="/hirewise"
                     />
                  </div>
                  <ContactsInfo
                     sectionTitle="Reach out"
                     titleGap="14px"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(1)}
                  />
                  <SocialMedia
                     squareIcons
                     titleGap="14px"
                     data-aos="fade-up"
                     data-aos-delay={delayFactor(2)}
                  />
               </RightContainer>
            )}
         </div>
      </Container>
   );
};

export default ContactUsTemplate;
