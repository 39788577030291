import "../components/font-awesome";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import ContactUsTemplate from "../templates/contactUs";
import Footer from "../components/homePage/footer";

const SeoWrapper = styled.div``;

const ContactUsPage = () => {
   return (
      <SeoWrapper>
         <Seo title={"Contact us"} />
         <Layout>
            <ContactUsTemplate />
            <Footer />
         </Layout>
      </SeoWrapper>
   );
};

export default ContactUsPage;
